import React from "react";
import {
   FaAngellist, FaCoffee, FaEnvelope, FaGithub, FaInstagram, FaLinkedin, FaPatreon, FaRss, FaTwitch, FaTwitter, FaYoutube
} from 'react-icons/fa';

const Components = {
   angellist: FaAngellist,
   email: FaEnvelope,
   kofi: FaCoffee,
   github: FaGithub,
   instagram: FaInstagram,
   linkedin: FaLinkedin,
   patreon: FaPatreon,
   rss: FaRss,
   twitch: FaTwitch,
   twitter: FaTwitter,
   youtube: FaYoutube,
};

function Icons( profile ) {
   if (typeof Components[profile.component] !== "undefined") {
      return React.createElement(Components[profile.component], {
         // key: profile.name
         // color: profile.color
      });
   }

   return React.createElement(
      () => <div>The component {profile.component} has not been created yet.</div>,
      {
         // key: profile.name
      }
   );
}

export default Icons