import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image'
// import SocialIcons from "./social-icons";
import Icons from "./icons";

import "../styles/index.sass";
import "../styles/prism-code-blocks.css"

const Layout = ({ children }) => {
   const [showMenu, setShowMenu] = useState(false);

   const data = useStaticQuery(
      graphql`
         query {
            allSocialJson(
               filter: { display: { eq: true } }
               sort: { fields: order, order: ASC }
            ) {
               edges {
                  node {
                     name
                     url
                     title
                     component
                     color
                     bgcolor
                  }
               }
            }
            mdx(fields: { name: { eq: "sidebar" } }) {
               frontmatter {
                  title
                  subtitle
                  introText
                  copyright
                  created
                  iconImage {
                     childImageSharp {
                        fluid(maxWidth: 30) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
                  bioImage {
                     childImageSharp {
                        fluid(maxWidth: 300) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
         }
      `
   );

   const sidebar = data.mdx.frontmatter;
   const profiles = data.allSocialJson;
   // const siteMeta = data.site.siteMetadata

   return (
      <div className={`container ${showMenu ? "is-open" : ""}`}>
         {/* <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
         /> */}
         <div className="container__sidebar">
            <div className="sidebar">
               <div className="sidebar__header">
                  <div className="sidebar__icon sidebar__pixelated">
                     <Link to="/"><Img fluid={sidebar.iconImage.childImageSharp.fluid} /></Link>
                  </div>
                  <div>
                     <h6 className="sidebar__title">
                        <Link to="/">{sidebar.title}</Link>
                     </h6>
                     <div className="sidebar__subtitle">
                        {sidebar.subtitle}
                     </div>
                  </div>
               </div>
               {/* <Img fluid={sidebar.bioImage.childImageSharp.fluid} /> */}
               <div
                  className="sidebar__intro"
                  dangerouslySetInnerHTML={{
                     __html: sidebar.introText,
                  }}
               />
               <ul className="sidebar__menu">
                  <li>
                     <Link to="/">Home</Link>
                  </li>
                  <li>
                     <Link to="/projects">Projects</Link>
                  </li>
                  {/* <li>
                     <Link to="/skills">Skills</Link>
                  </li> */}
                  <li>
                     <Link to="/tags">Tags</Link>
                  </li>
                  <li>
                     <Link to="/projects/writing-samples">Writing Samples</Link>
                  </li>
                  <li>
                     <a href="/files/resume-jerry_belich.pdf"
                        type="application/pdf">
                        Resume
                     </a>
                  </li>
                  <li>
                     <a href="/files/artist_cv-jerry_belich.pdf"
                        type="application/pdf">
                        Artist CV
                     </a>
                  </li>
               </ul>
               <div className="sidebar__social social-icons">
                  <ul>
                     {/* <SocialIcons social={siteMeta} /> */}
                     {/* {profiles.edges.map(profile => Icons(profile))} */}
                     {profiles.edges.map(({ node: profile }) => (
                        <li key={profile.name}>
                           <a
                              href={profile.url}
                              className=""
                              title={profile.title}
                              target="_blank"
                              rel="noopener noreferrer"
                              // style={{
                              //    svg.color: `${profile.color}`
                              // }}
                           >
                              {Icons(profile)}
                           </a>
                        </li>
                     ))}
                  </ul>
               </div>
               <div className="sidebar__copyright">{sidebar.copyright}</div>
               <div className="sidebar__created">{sidebar.created}</div>
            </div>
         </div>
         <div className="container__body">
            <div className="container__mobile-header">
               <div className="mobile-header">
                  <button
                        aria-label="Show Menu"
                        className="mobile-header__button"
                        onClick={(e) => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                     }}
                  ></button>
                  {/* This is the original mobile menu, replaced by the above. */}
                  {/* <div className="mobile-header__menu">
                     <a
                     href="#menu"
                     onClick={e => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                     }}
                     />
                  </div> */}
                  <div className="mobile-header__logo">
                     <Link to="/">{sidebar.title}</Link>
                  </div>
               </div>
            </div>
            {children}
         </div>
      </div>
   );
};

// {social.edges.map(({ node: profile }) => (
//    <div key={profile.name}>
//    <Icon name={profile.icon} />
//    </div>
//    // <a
//    //    key={profile.name}
//    //    href={profile.url}
//    //    target="blank"
//    //    className={`social social--${profile.name.toLowerCase()}`}
//    //    style={{
//    //       backgroundImage: `url(${profile.iconUrl})`
//    //    }}
//    // >
//    // {" "}
//    // </a>
// ))}

// // Example graphQL fragment.
// export const avatarsFragmentQuery = graphql`
//   fragment avatars on RootQueryType {
//     avatars: allImageSharp {
//       edges {
//         node {
//            id

//          }
//        }
//     }
//   }
// `

// // Using it in another page.
// export const pageQuery = graphql`
//   query BlogQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }

//     ...avatars
//   }
// `

export default Layout